import React from 'react'
import unified from 'unified'
import parser from 'rehype-parse'
import toc, { Options } from '@jsdevtools/rehype-toc'
import rehypeReact from 'rehype-react'
import {
  Box,
  Typography,
  TypographyProps,
  Link as MuiLink,
} from '@material-ui/core'
import {
  Section,
  Chapter,
  SubSubSection,
  SubSection,
  Paragraph,
  Caption,
} from '../components/Typography'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const processor = unified()
  .use(parser, { fragment: true })
  .use(rehypeReact, {
    createElement: React.createElement,
    components: {
      h1: Chapter,
      h2: Section,
      h3: SubSection,
      h4: SubSubSection,
      h5: Caption,
      p: Paragraph,
      a: (props: any) => <MuiLink component={OutboundLink} {...props} />,
      li: (props: TypographyProps) => (
        <li>
          <Typography variant="body1" component="span" {...props} />
        </li>
      ),
      iframe: (
        props: React.DetailedHTMLProps<
          React.IframeHTMLAttributes<HTMLIFrameElement>,
          HTMLIFrameElement
        >
      ) => (
        <Box maxWidth={960} height="56.25vw" maxHeight={540}>
          <iframe {...props} width="100%" height="100%" />
        </Box>
      ),
      img: (
        props: React.DetailedHTMLProps<
          React.ImgHTMLAttributes<HTMLImageElement>,
          HTMLImageElement
        >
      ) => {
        if (!props.src?.startsWith('https://images.microcms-assets.io'))
          return <img {...props} style={{ maxWidth: '100%', maxHeight: 720 }} />

        const url = new URL(props.src)
        const params = new URLSearchParams(url.search)
        const width = params.get('w')
        const height = params.get('h')

        const mobileSize: Record<string, string> = { w: '600' }
        if (width && Number(width) < 600) mobileSize.w = width
        if (height) mobileSize.h = height
        const mobileParams = new URLSearchParams({
          ...mobileSize,
          q: '45',
          cs: 'tinysrgb',
          fit: 'max',
        })

        const pcSize: Record<string, string> = { w: '1280', h: '720' }
        if (width && Number(width) < 1280) pcSize.w = width
        if (height && Number(height) < 720) pcSize.h = height
        const pcParams = new URLSearchParams({
          ...pcSize,
          q: '45',
          cs: 'tinysrgb',
          fit: 'max',
        })
        return (
          <OutboundLink
            href={`${url.origin}${url.pathname}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <picture>
              <source
                media="(max-width: 600px)"
                srcSet={`${url.origin}${
                  url.pathname
                }?${mobileParams.toString()}&fm=webp`}
                style={{ maxWidth: '100%' }}
                type="image/webp"
              />
              <source
                media="(max-width: 600px)"
                srcSet={`${url.origin}${
                  url.pathname
                }?${mobileParams.toString()}`}
                style={{ maxWidth: '100%' }}
              />
              <source
                srcSet={`${url.origin}${
                  url.pathname
                }?${pcParams.toString()}&fm=webp`}
                style={{ maxWidth: '100%' }}
                type="image/webp"
              />
              <img
                src={`${url.origin}${url.pathname}?${pcParams.toString()}`}
                style={{ maxWidth: '100%' }}
              />
            </picture>
          </OutboundLink>
        )
      },
    },
  })

export const render = (html?: string) =>
  html
    ? ((processor.processSync(html) as unknown) as {
        result: React.ReactElement
      }).result
    : ''

export const renderWithToc = (html?: string, tocOptions?: Options) =>
  html
    ? ((processor().use(toc, tocOptions).processSync(html) as unknown) as {
        result: React.ReactElement
      }).result
    : ''
